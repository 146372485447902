import React, { useEffect, useState } from "react";
import banner from "./images/background/banner_bg.jpg";
import sidebarlog1 from "./images/sidebar_logo_1.png";
import logoBlue from "./images/sollauncher.png";
import English from "./images/english.png";
import flag1 from "./images/flags/006-united-states.svg";
import canada from "./images/flags/002-canada.svg";
import vietname from "./images/flags/003-vietnam.svg";
import france from "./images/flags/004-france.svg";
import germany from "./images/flags/005-germany.svg";
import Home1 from "./images/menu_img/home_1.jpg";
import Home2 from "./images/menu_img/home_2.jpg";
import Home3 from "./images/menu_img/home_3.jpg";
import Home4 from "./images/menu_img/home_4.jpg";
import bannerb1 from "./images/banner/b1.png";
import bannerb2 from "./images/banner/b2.png";
import bannerb3 from "./images/banner/b3.png";
import bannerb4 from "./images/banner/b4.png";
import bannerb5 from "./images/banner/b5.png";
import bannerImage from "./images/banner/banner_img.png";
import Icon1 from  "./images/feature/icon-1.png";
import Icon2 from  "./images/feature/icon-2.png";
import Icon3 from  "./images/feature/icon-3.png";
import  logostyle1 from "./images/client_logo/logo_style1.png";
import  logostyle2 from "./images/client_logo/logo_style2.png";
import  logostyle3 from "./images/client_logo/logo_style3.png";
import  logostyle4 from "./images/client_logo/logo_style4.png";
import  logostyle5 from "./images/client_logo/logo_style5.png";
import poligonal from "./images/feature/poligonal.png";
import blockimg from "./images/blockchain/block_img.png";
import videoimg from "./images/how-works/video.jpg";
import label1  from "./images/token/label_img1.png";
import label2  from "./images/token/label_img2.png";
import label3  from "./images/token/label_img3.png";
import label4  from "./images/token/label_img4.png";
import timeliner from "./images/roadmap/timelinered.png";
import windowslogo from "./images/documentation/windows_logo-1.png";
import windowslogo1 from "./images/documentation/windows_logo-2.png"
import mac from "./images/documentation/mac_ico.png"
import linux from "./images/documentation/linux_1.png"
import android from "./images/documentation/android_1.png"
import walterPhone from "./images/documentation/wallter_phones.png"
import whitepaper from "./images/documentation/whitepaper.png" 
import iconpng1 from "./images/documentation/icon1.png"
import signal from "./images/documentation/signal.png"
import iconpng2 from "./images/documentation/icon2.png"
import iconpng3 from "./images/documentation/icon3.png"
import iconpng4 from "./images/documentation/icon4.png"
import iconpng5 from "./images/documentation/icon5.png"
import globe1 from "./images/documentation/globe1.png" 
import globe2 from "./images/documentation/globe2.png" 
import team1 from "./images/team/team_1.jpg"
import team2 from "./images/team/team_2.jpg"
import team3 from "./images/team/team_3.jpg"
import team4 from "./images/team/team_4.jpg"
import featured from "./images/blog/blog-featured-img.jpg"
import blogpost from "./images/blog/blog_post_1.jpg"
import blogpost2 from "./images/blog/blog_post_2.jpg"
import blogpost3 from "./images/blog/blog_post_3.jpg"
import tryptologo from "./images/trypto_logo_blue.png"



import "./custom.css";

const Home = () => {

  

  return (
    <React.Fragment>
     
       

       

       

        <div>
       <div class="search-wrap">
          <form class="search-inner" id="main_search" method="get">
            <input
              id="search_text"
              type="search"
              placeholder="Search..."
              autocomplete="off"
            />
            <button id="searchbtn" type="submit">
              <i class="fa fa-search"></i>
            </button>
          </form>
        </div>

        {/* <!-- offset cart strart -->
     <!-- sidebar cart item --> */}
        <div class="xs-sidebar-group cart-group">
          <div class="xs-overlay xs-bg-black"></div>
          <div class="xs-sidebar-widget">
            <div class="sidebar-widget-container">
              <div class="widget-heading media">
                <div class="media-body">
                  <a href="#" class="close-side-widget">
                    <i class="icon icon-cross"></i>
                  </a>
                </div>
              </div>
              <div class="xs-empty-content">
                <h3 class="widget-title">Shopping cart</h3>
                <h4 class="xs-title">No products in the cart.</h4>
                <p class="empty-cart-icon">
                  <i class="icon icon-shopping-cart"></i>
                </p>
                <p class="xs-btn-wraper">
                  <a class="btn btn-primary" href="#">
                    Return To Shop
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- END sidebar cart item -->    <!-- END offset cart strart -->
     
     
     <!-- sidebar cart item --> */}
        <div class="xs-sidebar-group info-group">
          <div class="xs-overlay xs-bg-black"></div>
          <div class="xs-sidebar-widget">
            <div class="sidebar-widget-container">
              <div class="widget-heading">
                <a href="#" class="close-side-widget">
                  <i class="icon icon-cross"></i>
                </a>
              </div>
              <div class="sidebar-textwidget">
                <div class="sidebar-logo-wraper">
                  <img src={sidebarlog1} alt="sidebar logo" />
                </div>
                <p>
                  Far far away, behind the word moun tains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                  Separated they live in Bookmarksgrove right at the coast of{" "}
                </p>
                <ul class="sideabr-list-widget">
                  <li>
                    <div class="media">
                      <div class="d-flex">
                        <i class="icon icon-placeholder2"></i>
                      </div>
                      <div class="media-body">
                        <p>759 Pinewood Avenue</p>
                        <span>Marquette, Michigan</span>
                      </div>
                    </div>
                    {/* <!-- address 1 --> */}
                  </li>
                  <li>
                    <div class="media">
                      <div class="d-flex">
                        <i class="icon icon-email2"></i>
                      </div>
                      <div class="media-body">
                        <a href="mailto:info@domain.com">info@domain.com</a>
                        <span>Online Support</span>
                      </div>
                    </div>
                    {/* <!-- address 1 --> */}
                  </li>
                  <li>
                    <div class="media">
                      <div class="d-flex">
                        <i class="icon icon-phone-call2"></i>
                      </div>
                      <div class="media-body">
                        <p>906-624-2565</p>
                        <span>Mon-Fri 8am-5pm</span>
                      </div>
                    </div>
                    {/* <!-- address 1 --> */}
                  </li>
                </ul>
                {/* <!-- .sideabr-list-widget --> */}
                <div class="subscribe-from">
                  <p>Get Subscribed!</p>
                  <form
                    action="#"
                    method="POST"
                    id="subscribe-form"
                    class="subscribe-form"
                  >
                    <label for="sub-input"></label>
                    <div class="form-group">
                      <input
                        type="email"
                        name="email"
                        id="sub-input"
                        placeholder="Enter your mail here"
                        class="form-control"
                      />

                      <button class="sub-btn" type="submit">
                        <i class="icon icon-arrow-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
                <ul class="social-list version-2">
                  <li>
                    <a href="#" class="facebook">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" class="twitter">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" class="linkedin">
                      <i class="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" class="instagram">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" class="vimeo">
                      <i class="fa fa-vimeo"></i>
                    </a>
                  </li>
                </ul>
                {/* <!-- .social-list --> */}
                <div class="text-center">
                  <a href="#" class="btn btn-primary">
                    Purchase Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- END sidebar widget item -->    <!-- END offset cart strart -->
         */}

        {/* 
     <!-- header section start --> */}
        <header class="header">
          <div class="container">
            <nav id="navigation1" class="navigation">
              <div class="nav-header">
                <a class="nav-brand" href="index.html">
                  <img style={{width:"100px",height:"75px"}} src={logoBlue} alt="image" />
                </a>
                <div class="nav-toggle"></div>
              </div>

              <div class="nav-menus-wrapper xs-menu">
                <ul class="header-right align-to-right">
                  <li class="language">
                    <a href="#modal-popup-1" class="xs-modal-popup">
                    <img src={English} alt="image"/>
                    EN
                    </a>
                    {/* <!-- xs modal --> */}
                    <div
                      class="zoom-anim-dialog mfp-hide modal-language"
                      id="modal-popup-1"
                    >
                      <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                          <div class="language-content">
                            <p>Switch The Language</p>
                            <ul class="flag-lists">
                              <li>
                                <a href="#">
                                  <img
                                    src={flag1}
                                    alt="img"
                                  />
                                  <span>English</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <img
                                    src={canada}
                                    alt="img"
                                  />
                                  <span>English</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <img
                                    src={vietname}
                                    alt="img"
                                  />
                                  <span>Vietnamese</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <img
                                    src={france}
                                    alt="img"
                                  />
                                  <span>French</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <img
                                    src={germany}
                                    alt="img"
                                  />
                                  <span>German</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End xs modal --> */}
                  </li>
                  <li>
                    <a href="#" class="offset-side-bar">
                      <i class="icon icon-bag"></i>
                    </a>
                  </li>

                  <li>
                    <a href="#" class="searchIcon">
                      <i class="icon icon-search"></i>
                    </a>
                  </li>

                  <li>
                    <a href="#" class="navSidebar-button">
                      <i class="icon-menu2"></i>
                    </a>
                  </li>
                </ul>
                <ul class="nav-menu align-to-right">
                  <li class="active">
                    <a href="#">Home</a>
                    <div class="megamenu-panel">
                      <div class="megamenu-panel-row">
                        <div class="col-lg-4 col-12">
                          <a href="index.html">
                            <img src={Home1} style={{ width: "100%" }} alt="image" />
                            <span>Home One</span>
                          </a>
                        </div>
                        <div class="col-lg-4 col-12">
                          <a href="index-2.html">
                            <img src={Home2} style={{ width: "100%" }} alt="image" />
                            <span>Home Two</span>
                          </a>
                        </div>
                        <div class="col-lg-4 col-12">
                          <a href="index-3.html">
                            <img src={Home3} style={{ width: "100%" }} alt="image" />
                            <span>Home Three</span>
                          </a>
                        </div>
                        <div class="col-lg-4 col-12">
                          <a href="index-4.html">
                            <img src={Home4} style={{ width: "100%" }} alt="image" />
                            <span>Home Four</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#featured" class="scrolls">
                      Features
                    </a>
                  </li>
                  <li>
                    <a href="#how_work" class="scrolls">
                      How it work
                    </a>
                  </li>
                  <li>
                    <a href="#team" class="scrolls">
                      Team
                    </a>
                  </li>
                  <li>
                    <a href="#blog" class="scrolls">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          {/* <!-- .container END --> */}
        </header>
        {/* <!-- header section end --> */}

        <div id="particles-js">
          {/* <!-- banner start --> */}
          <section class="banner-sec" id="particles-js1">
            <div
              className="banner-item banner"
              style={{backgroundImage: `url(${banner})`}}
              // style="background: url(assets/images/background/banner_bg.jpg) no-repeat center center /cover"
            >
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 mx-auto">
                    <div class="banner-content">
                     
                      <span class="banner-grad">Sol-Launcher</span>
                      <br/>
                      <br/>
                      <p>
                      Sol-Launcher  is the first Multichain IDO platform for Solana !
                      </p>
                      <a href="#" class="btn btn-primary">
                        Get A Quote
                      </a>
                    </div>
                  </div>
                </div>

                <div class="banner-img-item">
                  <img src={bannerImage} class="banner-img" alt="image" />
                  <img  src={bannerb1} class="banner-ico banner-ico-1" alt="image" />
<img  src={bannerb2}class="banner-ico banner-ico-2" alt="image" />
<img  src={bannerb3}class="banner-ico banner-ico-3" alt="image" />
<img  src={bannerb4}class="banner-ico banner-ico-4" alt="image" />
<img  src={bannerb5}class="banner-ico banner-ico-5" alt="image" />
                </div>
              </div>
              {/* <!--  .cont  ainer END --> */}
            </div>
          </section>
          {/* <!-- banner section end --> */}

          <div class="featured-area">
            <div class="blockcain-and-featured-area">
              {/* <!-- blockcain section start --> */}
              <section class="blockcain-top-sec">
                <div class="container">
                  <div class="row">
                    <div class="col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                      <div class="blockcain-top">
                        <span class="column-title">
                        Welcome to Sol-Launcher Finance
                        </span>
                      </div>
                    </div>
                    {/*  */}
                    <div
                      class="col-md-5 offset-md-1 wow fadeInUp"
                      data-wow-duration="2s"
                    >
                      <div class="blockcain-top-content">
                        <p>
                        The goal of Sol-Launcher is to become the go-to IDO platform integrating all major Layer 1 chains.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <!-- blockcain section end -->
     
                 <!-- featured section start --> */}
              <section class="featured-sec" id="featured">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-8 mx-auto">
                      <div class="section-title-item">
                        <small class="xs-section-title">Main Features</small>
                        <h2 class="section-title">Blockchain Features</h2>
                      </div>
                    </div>
                  </div>
                  {/* <!-- row end --> */}
                  <div class="main-fetured-item">
                    <div class="row">
                      <div
                        class="col-md-4 wow fadeInUp"
                        data-wow-duration="1.5s"
                      >
                        <div class="single-feaured-item">
                          <img src={Icon1} alt="image" />
                          <h3 class="feature-title">Lowest Transaction Cost</h3>
                          <p>
                            A better way to present your money using fully
                            featured digital
                          </p>
                        </div>
                      </div>
                      {/*  */}
                      <div class="col-md-4 wow fadeInUp" data-wow-duration="2s">
                        <div class="single-feaured-item">
                          <img src={Icon2} alt="image" />
                          <h3 class="feature-title">Safe Smart Contracts</h3>
                          <p>
                            A better way to present your money using fully
                            featured digital
                          </p>
                        </div>
                      </div>
                      {/*  */}
                      <div
                        class="col-md-4 wow fadeInUp"
                        data-wow-duration="2.5s"
                      >
                        <div class="single-feaured-item">
                          <img src={Icon3} alt="image" />
                          <h3 class="feature-title">
                            Fast Blockchain Protocol
                          </h3>
                          <p>
                            A better way to present your money using fully
                            featured digital
                          </p>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                    {/* <!-- row end --> */}
                  </div>
                  {/* <!-- main-fetured-item end --> */}

                  <div
                    class="featured-poligonal-img wow fadeInUp"
                    data-wow-duration="1.5s"
                  >
                    <img src={poligonal} class="poligonal-img" alt="image" />
                  </div>
                </div>
              </section>
              {/* <!-- featured section end --> */}
            </div>
            <div class="blockcain-and-logo-area">
              {/* <!-- blockcain business section start --> */}
              <section class="blockcain-business-sec">
                <div class="container">
                  <div class="row">
                    <div
                      class="col-md-6 col-lg-6 wow fadeInUp"
                      data-wow-duration="1.5s"
                    >
                      <div class="blockcain-img">
                        <img src={blockimg} alt="image" />
                      </div>
                    </div>
                    {/*  */}
                    <div
                      class="col-md-6 col-lg-5 offset-lg-1 wow fadeInUp"
                      data-wow-duration="2s"
                    >
                      <div class="blockcain-content">
                        <small class="xs-section-title">
                          Business solution
                        </small>
                        <h3 class="column-title">Blockchain for Business</h3>
                        <p>
                          A better way to present your money using fully
                          featured digital currency and now available on the
                          team xpeed studio for everyone! It’s the fastest and
                          most scalable digital asset, enabling real-time
                          contracts and apps any where in the world.
                        </p>
                        <p>
                          Bitcoin Ethereum blockchains are slow and expensive.
                          Cost of transactions is a high (and getting higher)
                          Speed of others transactions is low
                        </p>
                        <a href="#" class="btn btn-primary">
                          Get A Quote
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <!-- blockcain business section end -->
     
     
                 <!-- client logo section start --> */}
              <section class="client-logo-sec">
                <div class="container">
                  <div class="client-logo-item">
                    <div class="row owl-carousel" id="client-slider">
                      <div class="col-sm wow fadeInUp" data-wow-duration="1.5s">
                        <div class="client-logo">
                          <img src={logostyle1} alt="image" />
                        </div>
                      </div>
                      <div class="col-sm wow fadeInUp" data-wow-duration="2s">
                        <div class="client-logo">
                          <img src={logostyle2} alt="image" />
                        </div>
                      </div>
                      <div class="col-sm wow fadeInUp" data-wow-duration="2.5s">
                        <div class="client-logo">
                          <img src={logostyle3} alt="image" />
                        </div>
                      </div>
                      <div class="col-sm wow fadeInUp" data-wow-duration="3s">
                        <div class="client-logo">
                          <img src={logostyle4} alt="image" />
                        </div>
                      </div>
                      <div class="col-sm wow fadeInUp" data-wow-duration="3.5s">
                        <div class="client-logo">
                          <img src={logostyle5} alt="image" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <!-- client logo section end --> */}
            </div>
          </div>
        </div>
        <div class="how-work-and-token-area">
          {/* <!-- how work section start --> */}
          <section class="how-work-sec section-padding" id="how_work">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mx-auto">
                  <div class="section-title-item">
                    <small class="xs-section-title">Process</small>
                    <h2 class="section-title">How Crypto Works</h2>
                  </div>
                </div>
              </div>
              {/* <!-- row end --> */}
              <div class="row">
                <div
                  class="col-lg-4 col-md-6 wow fadeInUp "
                  data-wow-duration="1.5s"
                >
                  <div class="xs-count-down">
                    <h3 class="xs-single-title">ICO Ends in</h3>

                    <ul class="xs-counter-list">
                      <li>
                        <strong id="xs_days">24</strong>
                        <span>Day</span>
                      </li>
                      <li>
                        <strong id="xs_hours">24</strong>
                        <span>Hours</span>
                      </li>
                      <li>
                        <strong id="xs_minuts">24</strong>
                        <span>Minute</span>
                      </li>
                      <li>
                        <strong id="xs_second">24</strong>
                        <span>Second</span>
                      </li>
                    </ul>

                    <div class="xs-progress-bar">
                      <span class="progess-text-1">Sold: $28,985,879</span>
                      <span class="progess-text-2">$50,000</span>
                      <div class="xs-bar">
                        <div class="bar-bg"></div>
                      </div>
                    </div>

                    <div class="btn-wrapper text-center">
                      <a href="#" class="btn btn-primary">
                        Buy Crypto
                      </a>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div
                  class="col-lg-3 offset-lg-1 col-md-6 align-self-center wow fadeInUp"
                  data-wow-duration="2s"
                >
                  <div class="work-token-item">
                    <ul>
                      <li>
                        <strong> Pre-Sale Starts</strong>
                        <span> 05/08/2019</span>
                      </li>
                      <li>
                        <strong> Pre-Sale Terms</strong>
                        <span>First week 25% bonus and then 20% bonus</span>
                      </li>
                      <li>
                        <strong> Token Symbol</strong>
                        <span>XPEED</span>
                      </li>
                      <li>
                        <strong> Total Number of Tokens</strong>
                        <span>471,082,090</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-lg-4 align-self-center col-md-12 wow fadeInUp"
                  data-wow-duration="2.5s"
                >
                  <div class="work-video">
                    <img src={videoimg} alt="age" />
                    <a
                      href="https://www.youtube.com/watch?v=2X9eJF1nLiY"
                      class="video-btn xs-video"
                      data-effect="mfp-zoom-in"
                    >
                      <i class="icon icon-play-button2"></i>
                      <span class="btn-hover-anim"></span>
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- row end --> */}
            </div>
          </section>
          {/* <!-- how work section end --> */}

          <div class="token-roadmap-area">
            {/* <!-- how work section start --> */}
            <section class="token-distribution-sec section-padding">
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 mx-auto">
                    <div class="section-title-item">
                      <small class="xs-section-title">Token Details</small>
                      <h2 class="section-title">Token Distribution</h2>
                    </div>
                  </div>
                </div>
                {/* <!-- row end --> */}
                <div class="row">
                  <div class="col-lg-6 wow fadeInUp" data-wow-duration="1.5s">
                    <div class="row chart-gap">
                      <div class="col-sm-7 ml-lg-auto">
                        <canvas class="token-chart-item" id="myChart"></canvas>
                        <h3 class="xs-single-title">Token Distribution</h3>
                      </div>
                      <div class=" col-sm-5">
                        <ul class="chart-label">
                          <li>
                            <img  src={label1} alt="image" />
                            <span class="chart-bg1">10 %</span> To Community
                          </li>
                          <li>
                            <img src={label2}alt="image" />
                            <span class="chart-bg2">08 %</span> Reserved Fund
                          </li>
                          <li>
                            <img src={label3} alt="image" />
                            <span class="chart-bg3">12 %</span> Advisor Team
                          </li>
                          <li>
                            <img src={label4} alt="image" />
                            <span class="chart-bg4">70 %</span> Sold Globaly
                          </li>
                        </ul>
                      </div>
                      {/* <!-- Chart Label End --> */}
                    </div>
                    {/* <!-- Row End --> */}
                  </div>
                  <div class="col-lg-6 wow fadeInUp" data-wow-duration="2s">
                    <div class="row chart-gap">
                      <div class="col-sm-7 ml-lg-auto">
                        <canvas
                          class="token-chart-item"
                          id="myChartTwo"
                        ></canvas>
                        <h3 class="xs-single-title">Sale Contribution</h3>
                      </div>
                      <div class="col-sm-5">
                        <ul class="chart-label">
                          <li>
                            <img src={label1} alt="image" />
                            <span class="chart-bg1">10 %</span> To Community
                          </li>
                          <li>
                            <img  src={label2} alt="image" />
                            <span class="chart-bg2">08 %</span> Reserved Fund
                          </li>
                          <li>
                            <img  src={label3} alt="image" />
                            <span class="chart-bg3">12 %</span> Advisor Team
                          </li>
                          <li>
                            <img  src={label4} alt="image" />
                            <span class="chart-bg4">70 %</span> Sold Globaly
                          </li>
                        </ul>
                      </div>
                      {/* <!-- Chart Label End --> */}
                    </div>
                    {/* <!-- Row End --> */}
                  </div>
                </div>
                {/* <!-- Row End --> */}
              </div>
            </section>
            {/* <!-- how work section end -->
     
                 <!-- roadmap section start --> */}
            <section class="roadmap-sec section-padding">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-8 mx-auto">
                    <div class="section-title-item">
                      <small class="xs-section-title">Process</small>
                      <h2 class="section-title">Company Roadmap</h2>
                    </div>
                  </div>
                </div>
                {/* <!-- row end --> */}
                <div class="roadmap-timeline">
                  <img src={timeliner} alt="image" />
                  <div class="custom-container container">
                    <div class="row roadmap-timeline-item">
                      <div
                        class="col-md xs-roadmap wow fadeInUp"
                        data-wow-duration="1.5s"
                      >
                        <div class="single-roadmap-timeline">
                          <b></b>
                          <h3>April 2015</h3>
                          <p>Creation of Our Company</p>
                        </div>
                      </div>

                      <div
                        class="col-md xs-roadmap wow fadeInUp"
                        data-wow-duration="2s"
                      >
                        <div class="single-roadmap-timeline">
                          <b></b>
                          <h3>April 2016</h3>
                          <p>Reach $20 Million Milestone</p>
                        </div>
                      </div>

                      <div
                        class="col-md xs-roadmap wow fadeInUp"
                        data-wow-duration="2.5s"
                      >
                        <div class="single-roadmap-timeline">
                          <b></b>
                          <h3>March 2017</h3>
                          <p>Best ICO Brand</p>
                        </div>
                      </div>

                      <div
                        class="col-md xs-roadmap wow fadeInUp"
                        data-wow-duration="3s"
                      >
                        <div class="single-roadmap-timeline">
                          <b></b>
                          <h3>May 2017</h3>
                          <p>Partnership with Urban Wolfs</p>
                        </div>
                      </div>

                      <div
                        class="col-md xs-roadmap wow fadeInUp"
                        data-wow-duration="3.5s"
                      >
                        <div class="single-roadmap-timeline">
                          <b></b>
                          <h3>June 2018</h3>
                          <p>Invest in Bitcoin & Lisk</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="document-and-team-area">
          <section class="doceumnt-sec section-padding">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mx-auto">
                  <div class="section-title-item">
                    <small class="xs-section-title">Document</small>
                    <h2 class="section-title">The Documentation</h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-lg-8 mx-auto wow fadeInUp"
                  data-wow-duration="1.5s"
                >
                  <div
                    class="nav xs-tabs-menu"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="horizontal"
                  >
                    <a
                      class="active col-md"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-home"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <i class="icon-wallet"></i>
                      Wallet
                    </a>

                    <a
                      class="col-md"
                      id="v-pills-profile-tab"
                      data-toggle="pill"
                      href="#v-pills-paper"
                      role="tab"
                      aria-controls="v-pills-paper"
                      aria-selected="false"
                    >
                      <i class="icon-whitepaper"></i>
                      White Paper
                    </a>

                    <a
                      class="col-md"
                      id="v-pills-report-tab"
                      data-toggle="pill"
                      href="#v-pills-reports"
                      role="tab"
                      aria-controls="v-pills-reports"
                      aria-selected="false"
                    >
                      <i class="icon-report"></i>
                      Reports
                    </a>

                    <a
                      class="col-md"
                      id="v-pills-ico-tab"
                      data-toggle="pill"
                      href="#v-pills-ICO"
                      role="tab"
                      aria-controls="v-pills-ICO"
                      aria-selected="false"
                    >
                      <i class="icon-structure"></i>
                      ICO Structure
                    </a>
                  </div>

                  <div class="road-map-tab">
                    <div class="tab-content" id="v-pills-tabContent">
                      <div
                        class="tab-pane tab_item_1 show active"
                        id="v-pills-home"
                        role="tabpanel"
                        aria-labelledby="v-pills-home-tab"
                      >
                        <div class="row">
                          <div class="col-md-6 align-self-center">
                            <div class="xs-tabs-content">
                              <h3 class="xs-single-title">
                                Download Crypto Wallet
                              </h3>
                              <span class="release_vertion">
                                Latest Release 1.49.0
                              </span>

                              <ul class="download-logo">
                                <li>
                                  <img
                                    src={windowslogo}
                                    alt="image"
                                  />
                                </li>
                                <li>
                                  <img
                                    src={windowslogo1}
                                    alt="image"
                                  />
                                </li>
                                <li>
                                  <img src={mac} alt="image" />
                                </li>
                                <li>
                                  <img src={linux} alt="image" />
                                </li>
                                <li>
                                  <img src={android} alt="image" />
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="xs-tabs-img">
                              <img src={walterPhone} alt="image" />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- tab pane tab_item_1--> */}
                      <div
                        class="tab-pane tab_item_2"
                        id="v-pills-paper"
                        role="tabpanel"
                        aria-labelledby="v-pills-profile-tab"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <div class="xs-tabs-img text-center">
                              <img src={whitepaper} alt="image" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="xs-white-tabs-content">
                              <h3 class="xs-single-title">
                                Download Whitepaper
                              </h3>
                              <p>
                                A better way to present your money using fully
                                featured digital currency and now available on
                                the team xpeed studio for It is the fastest and
                                most scalable digital asset,
                              </p>
                              <a href="#" class="btn btn-primary sm-btn">
                                Download Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- tab pane tab_item_2--> */}
                      <div
                        class="tab-pane tab_item_3"
                        id="v-pills-reports"
                        role="tabpanel"
                        aria-labelledby="v-pills-report-tab"
                      >
                        <div class="report-table table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Price</th>
                                <th>24 hr% change</th>
                                <th>market Cap</th>
                                <th>24hr volume</th>
                                <th>price graph</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <img src={iconpng1} alt="image" /> Bitcoin
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img src={signal} alt="image" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={iconpng2} alt="image" /> Ethereum
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img src={signal} alt="image" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={iconpng1} alt="image" /> Bitcoin Cash
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img src={signal}
                                  alt="image" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={iconpng3} alt="image" /> Ripple
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img src={signal} alt="image" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={iconpng4} alt="image" /> IOTA
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img  src={signal} alt="image" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={iconpng1} alt="image" /> Bitcoin Gold
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img src={signal} alt="image" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={iconpng2} alt="image" /> Ethereum Classic
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img src={signal} alt="image" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={iconpng5} alt="image" /> OmiseGO
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img src={signal} alt="image" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={iconpng2} alt="image" /> Ethereum
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img src={signal} alt="image" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={iconpng1} alt="image" /> Bitcoin
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img src={signal} alt="image" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={iconpng2} alt="image" /> Ethereum
                                </td>
                                <td>$1097201</td>
                                <td class="change">-2.45%</td>
                                <td>$73.99B</td>
                                <td>$4.588</td>
                                <td>
                                  <img src={signal} alt="image" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <!-- tab pane tab_item_3--> */}
                      <div
                        class="tab-pane tab_item_4"
                        id="v-pills-ICO"
                        role="tabpanel"
                        aria-labelledby="v-pills-ico-tab"
                      >
                        <div class="ico-table table-responsive">
                          <table class="table">
                            <tr>
                              <td>Pre-Sale Starts</td>
                              <td>7/31/2018</td>
                            </tr>
                            <tr>
                              <td>Pre-Sale Period</td>
                              <td>7/31/2018</td>
                            </tr>
                            <tr>
                              <td>Pre-Sale Cap</td>
                              <td>7/31/2018</td>
                            </tr>
                            <tr>
                              <td>Pre-Sale Terms</td>
                              <td>7/31/2018</td>
                            </tr>
                            <tr>
                              <td>Token Sale Starts</td>
                              <td>7/31/2018</td>
                            </tr>
                            <tr>
                              <td>Token Sale Period</td>
                              <td>7/31/2018</td>
                            </tr>
                            <tr>
                              <td>Soft Cap</td>
                              <td>7/31/2018</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      {/* <!-- tab pane tab_item_4--> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- row end --> */}
            </div>
            {/* <!-- container end --> */}
            <div class="documentaion-shap-img">
              <img
                class="d-shap-img-1 wow fadeInLeft"
                data-wow-duration="1.5s"
                src={globe1}
                id="leftglobe"
                alt="image"
              />
              <img
                class="d-shap-img-2 wow fadeInRight"
                data-wow-duration="1.5s"
                src={globe2}
                alt="image"
              />
            </div>
          </section>
          {/* <!-- document section end --> */}

          {/* <!-- team section start --> */}
          <section class="team-sec section-padding" id="team">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mx-auto">
                  <div class="section-title-item">
                    <small class="xs-section-title">Our experts</small>
                    <h2 class="section-title">Team Members</h2>
                  </div>
                </div>
              </div>
              {/* <!-- row end --> */}
              <div class="row">
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-duration="1.5s"
                >
                  <div class="single-team">
                    <div class="team-img">
                      <img src={team1} alt="team" />
                      <div class="team-social">
                        <a href="#">
                          <i class="icon icon-facebook"></i>
                        </a>
                        <a href="#">
                          <i class="icon icon-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="icon icon-linkedin"></i>
                        </a>
                      </div>
                    </div>
                    <div class="team-content">
                      <h3 class="xs-title md">James Carter</h3>
                      <p>CEO</p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-duration="2s"
                >
                  <div class="single-team">
                    <div class="team-img">
                      <img src={team2} alt="team2" />
                      <div class="team-social">
                        <a href="#">
                          <i class="icon icon-facebook"></i>
                        </a>
                        <a href="#">
                          <i class="icon icon-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="icon icon-linkedin"></i>
                        </a>
                      </div>
                    </div>
                    <div class="team-content">
                      <h3 class="xs-title md">Jenifar Lorany</h3>
                      <p>Marketing Head</p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-duration="2.5s"
                >
                  <div class="single-team">
                    <div class="team-img">
                      <img src={team3} alt="team" />
                      <div class="team-social">
                        <a href="#">
                          <i class="icon icon-facebook"></i>
                        </a>
                        <a href="#">
                          <i class="icon icon-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="icon icon-linkedin"></i>
                        </a>
                      </div>
                    </div>
                    <div class="team-content">
                      <h3 class="xs-title md">Michel Ramos</h3>
                      <p>HR Head</p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-duration="3s"
                >
                  <div class="single-team">
                    <div class="team-img">
                      <img src={team4} alt="image" />
                      <div class="team-social">
                        <a href="#">
                          <i class="icon icon-facebook"></i>
                        </a>
                        <a href="#">
                          <i class="icon icon-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="icon icon-linkedin"></i>
                        </a>
                      </div>
                    </div>
                    <div class="team-content">
                      <h3 class="xs-title md">Lorence Katie</h3>
                      <p>Lead Designer</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Row End --> */}
            </div>
          </section>
          {/* <!-- team section end --> */}
        </div>

        <div class="blog-and-footer-area">
          <div class="blog-and-social-area">
            {/* <!-- blog section start --> */}
            <section class="blog-sec section-padding" id="blog">
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 mx-auto">
                    <div class="section-title-item">
                      <small class="xs-section-title">Media Coverage</small>
                      <h2 class="section-title">Blog and Media</h2>
                    </div>
                  </div>
                </div>
                {/* <!-- row end --> */}
                <div class="row">
                  <div class="col-lg-7 wow fadeInUp" data-wow-duration="1.5s">
                    <div class="blog-featured-post">
                      <a href="#">
                        <div class="blog-feaured-img">
                          <img src={featured} alt="image" />
                        </div>
                        <div class="blog-featured-content">
                          <span class="post-meta-date">20 August, 2018</span>
                          <h3 class="xs-blog-title">
                            Poor Communication Undermines the Bitcoin Ecosystem.
                          </h3>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-5 wow fadeInUp" data-wow-duration="2s">
                    <div class="blog-post">
                      <div class="single-blog-post media">
                        <div class="d-flex post-img">
                          <img src={blogpost} alt="blog post image" draggable="false" />
                        </div>
                        <div class="media-body">
                          <span class="post-meta-date">21 August , 2018</span>
                          <h4 class="xs-post-title">
                            <a href="blog-single.html">
                              IBM Reimagines Proof-of-Work for Blockchain IoT
                              Devices
                            </a>
                          </h4>
                        </div>
                      </div>
                      {/* <!-- single-blog-post End --> */}
                      <div class="single-blog-post media">
                        <div class="d-flex post-img">
                          <img src={blogpost2} alt="blog post image" draggable="false" />
                        </div>
                        <div class="media-body">
                          <span class="post-meta-date">21 August , 2018</span>
                          <h4 class="xs-post-title">
                            <a href="blog-single.html">
                              NASDAQ CEO Open Crypto NASDAQ Collaborating with
                              Gemini
                            </a>
                          </h4>
                        </div>
                      </div>
                      {/* <!-- single-blog-post End --> */}
                      <div class="single-blog-post media">
                        <div class="d-flex post-img">
                          <img src={blogpost3} alt="blog post image" draggable="false" />
                        </div>
                        <div class="media-body">
                          <span class="post-meta-date">21 August , 2018</span>
                          <h4 class="xs-post-title">
                            <a href="blog-single.html">
                              Crypto is Back Bull Mode, What Can We Learn From
                              This?
                            </a>
                          </h4>
                        </div>
                      </div>
                      {/* <!-- single-blog-post End --> */}
                    </div>
                    {/* <!-- blog post End --> */}
                  </div>
                </div>
                {/* <!-- Row End --> */}
              </div>
            </section>
            {/* <!-- blog section end --> */}

            {/* <!-- social list section start --> */}
            <section class="social-list-sec section-padding">
              <div class="container">
                <div class="row">
                  <div
                    class="col-lg col-6 col-md-3 wow fadeInUp"
                    data-wow-duration=".5s"
                  >
                    <div class="single-social-list facebook">
                      <i class="fa fa-facebook"></i>
                      <h3 class="xs-title sm">Facebook</h3>
                      <a href="#">
                        <span class="icon icon-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  {/* <!-- single-social-list--> */}
                  <div
                    class="col-lg col-6 col-md-3 wow fadeInUp"
                    data-wow-duration="1s"
                  >
                    <div class="single-social-list twitter">
                      <i class="fa fa-twitter"></i>
                      <h3 class="xs-title sm">Twitter</h3>
                      <a href="#">
                        <span class="icon icon-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  {/* <!-- single-social-list--> */}
                  <div
                    class="col-lg col-6 col-md-3 wow fadeInUp"
                    data-wow-duration="1.5s"
                  >
                    <div class="single-social-list github">
                      <i class="fa fa-github-alt"></i>
                      <h3 class="xs-title sm">Github</h3>
                      <a href="#">
                        <span class="icon icon-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  {/* <!-- single-social-list--> */}
                  <div
                    class="col-lg col-6 col-md-3 wow fadeInUp"
                    data-wow-duration="2s"
                  >
                    <div class="single-social-list slack">
                      <i class="fa fa-slack"></i>
                      <h3 class="xs-title sm">Slack</h3>
                      <a href="#">
                        <span class="icon icon-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  {/* <!-- single-social-list--> */}
                  <div
                    class="col-lg col-6 col-md-3 wow fadeInUp"
                    data-wow-duration="2.5s"
                  >
                    <div class="single-social-list bitcoin">
                      <i class="fa fa-btc"></i>
                      <h3 class="xs-title sm">BitTalk</h3>
                      <a href="#">
                        <span class="icon icon-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  {/* <!-- single-social-list--> */}
                  <div
                    class="col-lg col-6 col-md-3 wow fadeInUp"
                    data-wow-duration="3s"
                  >
                    <div class="single-social-list youtube">
                      <i class="fa fa-youtube-play"></i>
                      <h3 class="xs-title sm">Youtube</h3>
                      <a href="#">
                        <span class="icon icon-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  {/* <!-- single-social-list--> */}
                  <div
                    class="col-lg col-6 col-md-3 wow fadeInUp"
                    data-wow-duration="3.5s"
                  >
                    <div class="single-social-list medium">
                      <i class="fa fa-medium"></i>
                      <h3 class="xs-title sm">Medium</h3>
                      <a href="#">
                        <span class="icon icon-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  {/* <!-- single-social-list--> */}
                </div>
                {/* <!-- Row End --> */}
              </div>
            </section>
            {/* <!-- social list section end --> */}
          </div>

          {/* <!-- footer section start --> */}
          <footer class="xs-footer-sec">
            <div class="container">
              <div class="footer-area">
                <div class="row">
                  <div class="col-lg-3 col-sm-6">
                    <div class="footer-widget">
                      <div class="footer-logo">
                        <a href="index.html">
                          <img src={tryptologo} alt="image" />
                        </a>
                      </div>
                      <p>
                        A better way to present your money using fully featured
                        digital currency and now available on
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-2 offset-lg-1 col-sm-6">
                    <div class="footer-widget">
                      <h4 class="widget-title">Company</h4>
                      <ul>
                        <li>
                          <a href="#"> About us</a>
                        </li>
                        <li>
                          <a href="#"> Our Services</a>
                        </li>
                        <li>
                          <a href="#"> Timeline</a>
                        </li>
                        <li>
                          <a href="#"> On Media</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6">
                    <div class="footer-widget support">
                      <h4 class="widget-title">Support</h4>
                      <ul>
                        <li>
                          <a href="#"> Contact Us</a>
                        </li>
                        <li>
                          <a href="#"> FAQ</a>
                        </li>
                        <li>
                          <a href="#"> Support</a>
                        </li>
                        <li>
                          <a href="#"> Token</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-3 offset-lg-1 col-sm-6">
                    <div class="footer-widget">
                      <h4 class="widget-title">Contact</h4>
                      <form method="get" class="widget-subscibe">
                        <input
                          type="email"
                          name="email"
                          class="subscribe-email"
                          placeholder="Email"
                        />
                        <button type="submit" class="subs-btn">
                          <i class="fa fa-paper-plane"></i>{" "}
                        </button>
                      </form>
                      <ul>
                        <li>Tel: 009-215-5596 ( Toll free )</li>
                        <li>Email: info@example.com</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="footer-copyright">
                <p>Copyright © 2017 trypto Theme</p>
              </div>
            </div>
          </footer>
        </div>

        {/* <!--back to top start--> */}
        <div class="BackTo">
          <a href="#" class="fa fa-angle-up" aria-hidden="true"></a>
        </div>
        {/* <!--back to top end--> */}
      </div>
    </React.Fragment>
  );
};

export default Home;
