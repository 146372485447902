import logo from './logo.svg';
import React, { useEffect, useState } from "react";
import './App.css';
import Home from './Home';

function App() {
const [preloader,setPreloader]=useState(true);
 
  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/todos/1')
    .then(response => response.json())
    .then(json => {
      setPreloader(false)
    })
    .catch(err => {
      setPreloader(false)
    });
  }, []) 

  return (
    <div >
      {preloader?
      (<div class="preloader" id="preloader">
      <p class="browserupgrade">
     You are using an <strong>outdated</strong> browser. Please{" "}
     <a href="http://browsehappy.com/">upgrade your browser</a> to improve
     your experience.
   </p>
     <svg
       class="xs-preload"
       viewBox="0 0 120 120"
       width="120px"
       height="120px"
     >
       <circle class="inner" cx="60" cy="60" r="32" />
       <circle class="middle" cx="60" cy="60" r="38" />
       <circle class="outer" cx="60" cy="60" r="44" />
     </svg>
     <a class="cencel-preloader" href="">
       Cancel Preloader
     </a>
   </div>)
    :( <Home/>)}

    </div>
  );
}

export default App;
